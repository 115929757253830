import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { RequestService } from '../request.service';
import {
  GetTemplateCodeReq,
  GetTemplateReq,
  ProjectElectricPriceModeResponse,
  RptAccountBillTypeResponse, RptPrepayRechargePredictDetailRequest, RptPrepayRechargePredictDetailResponse,
  RptRenterSatisfactionDetailRequest,
  RptRenterSatisfactionDetailResponse,
  RptRoomPrepayDetailResponse,
  RptWorkRemindSearchListRequest,
  RptWorkRemindSearchListResponse,
  RunNightSettleReq
} from "../../model/rpt/rpt-template.model";
import {RoomReadingResponse} from "../../model/reading/RoomReading.model";

/**
 * @Description: 报表服务
 * @Author: 陆炳彬
 * @Date: 2018/3/29 上午10:23
 * @Last Modified by: 陆炳彬
 * @Last Modified time: 2018/3/29 上午10:23
 */
@Injectable()
export class RptService {

  private SETTLE_DAY_DETAILS = `${environment.apiHost}rpt/day/settle/now/details`;
  private RUN_SETTLE_DAY = `${environment.apiHost}rpt/day/settle/run`;
  private SETTLE_DAY_DOWNLOAD_URL = `${environment.apiHost}rpt/download`;
  private REPORT_TEMPLATE_LIST_URL = `${environment.apiHost}rpt/template/list`;
  private GENERATE_TEMPLATE = `${environment.apiHost}rpt/generate/template`;
  private RUN_SETTLE_NIGHT = `${environment.apiHost}job/rpt/start?securitykey=OKKBLO23249slKng2oiasglkjLkngkl2-safoiyglkwflk293758`;
  private DAY_SETTLE_DOWNLOAD = `${environment.apiHost}rpt/download/day/settle/report`;
  private GET_INITIAL_LEASE = `${environment.apiHost}rpt/get/initial/lease`;
  private NIGHT_SETTLE_BATTCH_RUN = `${environment.apiHost}rpt/night/settle/batch/run`;
  private GET_TEMPLATE = `${environment.apiHost}rpt/template/query`;

  private ACCOUNT_SETTLE_BATTCH_RUN = `${environment.apiHost}rpt/account/settle/batch/run`;

  private RPT_ENTERLEAVE = `//t-img1-mamahome.oss-cn-shanghai.aliyuncs.com/json/enterLeave.json`;
  private RPT_ROOMUSE = `//t-img1-mamahome.oss-cn-shanghai.aliyuncs.com/json/roomUse.json`;
  private RPT_SERVICE_STATISTICS = `//t-img1-mamahome.oss-cn-shanghai.aliyuncs.com/json/serviceStatistics.json`;
  // private RPT_ENTERLEAVE = `http://pmsjson.oss-cn-beijing.aliyuncs.com/json/enterLeave.json`;
  // private RPT_ROOMUSE = `http://pmsjson.oss-cn-beijing.aliyuncs.com/json/roomUse.json`;
  // private RPT_SERVICE_STATISTICS = `http://pmsjson.oss-cn-beijing.aliyuncs.com/json/serviceStatistics.json`;

  private GET_TEMPLATE_BYCODE = `${environment.apiHost}rpt/template/queryBycode`;

  //用户目标群体
  private GET_USER_GROUP = `${environment.apiHost}user/face/user/group`;
  //用户群体分布
  private GET_USER_GROUP_DISTRIBUTION = `${environment.apiHost}user/face/user/group/staff/type`;
  //用户行业分布
  private GET_USER_WORK_DISTRIBUTION = `${environment.apiHost}user/face/user/group/industry/type`;
  //年龄城市性别手机品牌学历居住时长
  private GET_USER_GROUP_TYPE = `${environment.apiHost}user/face/user/group/user/information`;
  //客单价消费力
  private GET_USER_FACE_MONEY = `${environment.apiHost}user/face/consumption/money`;
  //用户消费频次
  private GET_USER_FACE_FREQUENCY = `${environment.apiHost}user/face/consumption/frequency`;
  //用户消费总金额
  private GET_USER_FACE_MOMEY_SUM = `${environment.apiHost}user/face/consumption/money/sum`;
  //消费最大值和最小值
  private GET_USER_FACE_MAX = `${environment.apiHost}user/face/consumption/max/min/money`;
  //在线消费统计
  private GET_USER_FACE_STATISTICS = `${environment.apiHost}user/face/consumption/statistics`;
  //爱好服务
  private GET_USER_FACE_HOBBY = `${environment.apiHost}user/face/service/hobby`;
  // 房间期初末读数
  private RPT_ROOM_READING_GET_INFO = `${environment.apiHost}rpt/room/reading/get/info`;

  private RPT_OPEN_DOOR_SEARCH_LIST = `${environment.apiHost}rpt/open/door/search/list`;

  private RPT_WENT_DUTCH_SEARCH_RENTER_DATA = `${environment.apiHost}rpt/went/dutch/search/renter/data`;
  private RPT_WENT_DUTCH_SEARCH_SUBJECT_DATA = `${environment.apiHost}rpt/went/dutch/search/subject/data`;

  private RPT_WENT_DUTCH_GET_BILL_TYPE = `${environment.apiHost}rpt/went/dutch/get/bill/type`;

  // 预付费扣费明细报表
  private RPT_ROOM_PREPAY_DETAIL = `${environment.apiHost}rpt/room/prepay/detail`;

  // 项目电费定价方式
  private PROJECT_ELECTRIC_PRICE_MODE = `${environment.apiHost}rpt/room/prepay/project/electric/price/mode`;

  // 查询台账类型
  private RPT_TEMPLATE_ACCOUNT_BILL_TYPE = `${environment.apiHost}rpt/template/account/bill/type`;

  // 房间疫情封控报表
  private RPT_STAMP_PROJECT_DATA = `${environment.apiHost}rpt/stamp/project/data`;

  // 待办处理情况报表
  private RPT_WORK_REMIND_HANDLE_DETAIL = `${environment.apiHost}rpt/work/remind/list`;

  // 运营大脑社区满意度报表
  private RPT_RENTER_SATISFACTION_DETAIL = `${environment.apiHost}rpt/renter/satisfaction/detail`;

  // 每月水电费充值明细表
  private RPT_PREPAY_RECHARGE_PREDICT_SEARCH_DETAIL = `${environment.apiHost}rpt/prepay/recharge/predict/search/detail`;

  private RPT_OVERDUE_FINE = `${environment.apiHost}rpt/overdue/fine`;

  private RPT_LEASE_ACCOUNTING_SHOW = `${environment.apiHost}rpt/lease/accounting/show`;

  private RPT_CLIENT_USER_CONVERSION = `${environment.apiHost}rpt/client/user/conversion`;

  private RPT_CLIENT_USER_COMMENT_SCORE = `${environment.apiHost}rpt/client/user/comment/score`;

  private RPT_QUESTIONNAIRE_ISSUE_COMMENT = `${environment.apiHost}rpt/questionnaire/issue/comment`;

  constructor(private requestService: RequestService) {
  }

  /**
   * @description: 获取日审当前数据
   * @Author: lubingbin
   * @Date: 2018/3/21
   * @Last Modified by: lubingbin
   * @Last Modified time: 2018/3/21
   */
  getSettleDayNowDetails(): Promise<any> {
    return this.requestService.authGet(this.SETTLE_DAY_DETAILS);
  }

  /**
   * @description: 执行夜审
   * @Author: lubingbin
   * @Date: 2018/3/21
   * @Last Modified by: lubingbin
   * @Last Modified time: 2018/3/21
   */
  runSettleDay(password: string): Promise<any> {
    return this.requestService.authGet(this.RUN_SETTLE_DAY, { password: password });
  }

  /**
   * @description: 获取报表下载url
   * @Author: lubingbin
   * @Date: 2018/3/21
   * @Last Modified by: lubingbin
   * @Last Modified time: 2018/3/21
   */
  getSettleDayReportDownloadUrl(templateId: number, params: any, isDownload: boolean): string {
    let url = this.SETTLE_DAY_DOWNLOAD_URL + '?templateId=' + templateId + '&isDownload=' + isDownload
      + '&paramsStr=' + encodeURI(JSON.stringify(params));
    return url;
  }

  /**
   * @description: 获取报表列表
   * @Author: lubingbin
   * @Date: 2018/3/23
   * @Last Modified by: lubingbin
   * @Last Modified time: 2018/3/23
   */
  getRptTemplateList(reportType: Array<string>): Promise<any> {
    return this.requestService.authGet(this.REPORT_TEMPLATE_LIST_URL, { type: reportType });
  }

  /**
   * @description: 根据id获取报表
   * @Author: lubingbin
   * @Date: 2018/3/23
   * @Last Modified by: lubingbin
   * @Last Modified time: 2018/3/23
   */
  getTemplateDetails(templateId: number, params: any): Promise<any> {
    return this.requestService.authPost(this.GENERATE_TEMPLATE, { templateId: templateId, params: params });
  }
  getTemplateDetailsWithLoading(templateId: number, params: any): Promise<any> {
    return this.requestService.authLongPost(this.GENERATE_TEMPLATE, { templateId: templateId, params: params }, 2 * 60 * 1000, true);
  }

  /**
   * @Description: 执行夜审
   * @Author: 陆炳彬
   * @Date: 2018/6/8 下午2:00
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/6/8 下午2:00
   */
  settleNightRun() {
    return this.requestService.authGet(this.RUN_SETTLE_NIGHT);
  }

  /**
   * @Description: 日报下载
   * @Author: 陆炳彬
   * @Date: 2018/9/4 上午10:45
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/9/4 上午10:45
   */
  settleDayDownload(): String {
    return this.DAY_SETTLE_DOWNLOAD;
  }

  /**
   * @Description: 获取最早的租约
   * @Author: 陆炳彬
   * @Date: 2018/9/4 上午10:45
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/9/4 上午10:45
   */
  getInitialLease(): Promise<any> {
    return this.requestService.authGet(this.GET_INITIAL_LEASE);
  }

  /**
   * @Description: 重新夜审
   * @Author: 陆炳彬
   * @Date: 2018/9/4 上午11:02
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/9/4 上午11:02
   */
  runNightSettle(params: RunNightSettleReq): Promise<any> {
    return this.requestService.authLongGet(this.NIGHT_SETTLE_BATTCH_RUN, params, 10 * 60 * 1000);
  }

  /**
   * @Description: 根据id获取报表
   * @Author: 陆炳彬
   * @Date: 2018/9/4 下午2:26
   * @Last Modified by: 陆炳彬
   * @Last Modified time: 2018/9/4 下午2:26
   */
  getTemplate(params: GetTemplateReq): Promise<any> {
    return this.requestService.authGet(this.GET_TEMPLATE, params);
  }

  runAccountSettle(params: RunNightSettleReq): Promise<any> {
    return this.requestService.authLongGet(this.ACCOUNT_SETTLE_BATTCH_RUN, params, 10 * 60 * 1000);
  }
  enterLeave(req): Promise<any> {
    return this.requestService.authGet(this.RPT_ENTERLEAVE, req);
  }
  roomUse(req): Promise<any> {
    return this.requestService.authGet(this.RPT_ROOMUSE, req);
  }
  serviceStatistics(req): Promise<any> {
    return this.requestService.authGet(this.RPT_SERVICE_STATISTICS, req);
  }

  getTemplateBycode(params: GetTemplateCodeReq): Promise<any> {
    return this.requestService.authGet(this.GET_TEMPLATE_BYCODE, params);
  }
  getUserGroup(req): Promise<any> {
    return this.requestService.authGet(this.GET_USER_GROUP, req);
  }
  getGroupDistribution(req): Promise<any> {
    return this.requestService.authGet(this.GET_USER_GROUP_DISTRIBUTION, req);
  }
  getUserWork(req): Promise<any> {
    return this.requestService.authGet(this.GET_USER_WORK_DISTRIBUTION, req);
  }
  getUserFaceStatistics(req): Promise<any> {
    return this.requestService.authGet(this.GET_USER_FACE_STATISTICS, req);
  }
  getUserFaceMax(req): Promise<any> {
    return this.requestService.authGet(this.GET_USER_FACE_MAX, req);
  }
  getUserFaceMomeySum(req): Promise<any> {
    return this.requestService.authGet(this.GET_USER_FACE_MOMEY_SUM, req);
  }
  getUserFaceFrequency(req): Promise<any> {
    return this.requestService.authGet(this.GET_USER_FACE_FREQUENCY, req);
  }
  getUserFaceMoney(req): Promise<any> {
    return this.requestService.authGet(this.GET_USER_FACE_MONEY, req);
  }
  getUserGroupType(req): Promise<any> {
    return this.requestService.authGet(this.GET_USER_GROUP_TYPE, req);
  }
  getUserFaceHobby(req): Promise<any> {
    return this.requestService.authGet(this.GET_USER_FACE_HOBBY, req);
  }
  getRoomReadingInfo(req): Promise<RoomReadingResponse> {
    return this.requestService.authGet(this.RPT_ROOM_READING_GET_INFO, req);
  }
  openDoorSearchList(req): Promise<any> {
    return this.requestService.authGet(this.RPT_OPEN_DOOR_SEARCH_LIST, req,true);
  }
  getWentDutchBillType(req): Promise<any> {
    return this.requestService.authGet(this.RPT_WENT_DUTCH_GET_BILL_TYPE, req,true);
  }

  searchRptWentDutchRenter(req): Promise<any> {
    return this.requestService.authGet(this.RPT_WENT_DUTCH_SEARCH_RENTER_DATA, req,true);
  }

  searchRptWentDutchSubject(req): Promise<any> {
    return this.requestService.authGet(this.RPT_WENT_DUTCH_SEARCH_SUBJECT_DATA, req,true);
  }

  getRptRoomPrepayDetail(req): Promise<RptRoomPrepayDetailResponse> {
    return this.requestService.authGet(this.RPT_ROOM_PREPAY_DETAIL, req);
  }

  getProjectElectricPriceMode(): Promise<ProjectElectricPriceModeResponse> {
    return this.requestService.authGet(this.PROJECT_ELECTRIC_PRICE_MODE, null);
  }

  getRptAccountBillType(): Promise<RptAccountBillTypeResponse> {
    return this.requestService.authGet(this.RPT_TEMPLATE_ACCOUNT_BILL_TYPE, null);
  }

  getRptStampProjectData(req): Promise<any> {
    return this.requestService.authGet(this.RPT_STAMP_PROJECT_DATA,req,true);
  }

  getRptWorkRemindHandleDetail(req: RptWorkRemindSearchListRequest): Promise<RptWorkRemindSearchListResponse> {
    return this.requestService.authGet(this.RPT_WORK_REMIND_HANDLE_DETAIL, req,true);
  }

  getRptRenterSatisfactionDetail(req: RptRenterSatisfactionDetailRequest): Promise<RptRenterSatisfactionDetailResponse> {
    return this.requestService.authGet(this.RPT_RENTER_SATISFACTION_DETAIL, req,true);
  }

  getRptPrepayRechargePredictSearchDetail(req: RptPrepayRechargePredictDetailRequest): Promise<RptPrepayRechargePredictDetailResponse> {
    return this.requestService.authGet(this.RPT_PREPAY_RECHARGE_PREDICT_SEARCH_DETAIL, req,true);
  }

  getRptOverdueFine(req): Promise<any>{
    return this.requestService.authGet(this.RPT_OVERDUE_FINE, req,true);
  }

  getRptLeaseAccounting(req): Promise<any>{
    return this.requestService.authGet(this.RPT_LEASE_ACCOUNTING_SHOW, req,true);
  }
  getRptClientUserConversion(req): Promise<any>{
    return this.requestService.authGet(this.RPT_CLIENT_USER_CONVERSION, req,true);
  }
  getRptClientUserCommentScore(req): Promise<any>{
    return this.requestService.authGet(this.RPT_CLIENT_USER_COMMENT_SCORE, req,true);
  }

  getQuestionnaireIssueComment(req): Promise<any>{
    return this.requestService.authGet(this.RPT_QUESTIONNAIRE_ISSUE_COMMENT, req,true);
  }

}
