import {ProjectChangeResponse} from "../../model/project/project.model";
import {
  ArrangeLeaseRequest,
  Login,
  ProjectListResponse,
  ResetPassword,
  SendCode,
  LoginUnusualRequest,
  LoginUnusualResponse,
  LoginUnusualInserRequest,
  CommonResponse,
  UserOfterCityInserRequest,
  UserOfterCityResponse,
  UserDetailResponse,
  UserDetailRequest,
  UserWechatRelateFlagResponse,
  SmsCodeCheckRequest,
  LoginSecondCheckRequest,
  LoginCheckCode,
  UserProjectListRequest,
  ManageProjectListResponse,
  UserProjectRoomRelationRequest,
  UserWechatRelateFlagRequest, LeQiDaUserRegisterRequest, LeQiDaUserRegisterResponse, LeQiDaLoginSmsCodeRequest
} from '../../model/user/user.model';
import {RequestService} from '../request.service';
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';

@Injectable()
export class UserService {
  //员工列表
  private USER_LIST = `${environment.apiHost}user/queryUserList`;
  //用户增加或者修改
  private USER_MANAGE = `${environment.apiHost}user/manage`;

  //登录地址
  private USER_LOGIN = `${environment.apiHost}user/login`;

  // 发送验证码
  private USER_SEND_CODE = `${environment.apiHost}sms/mobile/code`;

  private USER_RESET_PASSWORD = `${environment.apiHost}user/reset/password`;
  //退出登录
  private USER_LOG_OUT = `${environment.apiHost}user/logout`;

  //部门设置：通过模糊查询获取员工列表
  private DEPT_USER_LIST = `${environment.apiHost}user/queryUserListByName`;
  //工单人员列表
  private WORKER_LIST = `${environment.apiHost}user/worker/list`;
  //工单人员新增、修改
  private WORKER_MANAGE = `${environment.apiHost}user/worker/manage`;
  //获取公司列表
  private COMPANY_LIST = `${environment.apiHost}sysCompany/getCompanyList`;
  //员工岗位管理
  private USER_POSITION = `${environment.apiHost}user/userPositionManage`;
  //获取员工基本信息
  private USER_INFO = `${environment.apiHost}user/queryUserInfo`;
  //获取员工项目列表
  private USER_PROJECT_LIST = `${environment.apiHost}user/project/list`;

  private USER_PROJECT_ISHOTWATER = `${environment.apiHost}user/project/isHotWater`;

  //修改员工项目
  private USER_PROJECT_CHANGE = `${environment.apiHost}user/project/change`;
  //获取员工项目
  private GET_USER_PROJECT = `${environment.apiHost}user/project/info`;
  // 获取此次登录是否需要验证码
  private USER_CODE_SHOW = `${environment.apiHost}user/code/show`;
  // 获取多设备登录消息
  private USER_TACTICS_MESSAGE = `${environment.apiHost}user/tactics/message`;
  // 踢除指定设备
  private USER_KICKTOKEN = `${environment.apiHost}user/KickToken`;
  // 员工离职交接合同
  private LEAVE_ARRANGE = `${environment.apiHost}user/leave/arrange`;

  //登录记录
  private LOGIN_UNUSUAL_LIST = `${environment.apiHost}user/loginUnusual/list`;
  //保存登录记录
  private ADD_LOGIN_UNUSUAL_RECORD = `${environment.apiHost}user/loginUnusual/add`;

  //设置常用登录城市
  private SETTING_OFTEN_CITY = `${environment.apiHost}user/setting/oftenCity`;
  //获取常用登录城市
  private OFTEN_CITY_LIST = `${environment.apiHost}user/oftenCity/List`;

  // 用户登录历史记录
  private LOGIN_HISTORY_LIST = `${environment.apiHost}user/login/history/list`;

  // 用户登录历史记录
  private SAVE_ROLES = `${environment.apiHost}user/save/roles`;
  // 保存所选公司
  private SAVE_CURRENT_COMPANY = `${environment.apiHost}user/save/current/company`;

  // 用户详情
  private USER_DETAIL = `${environment.apiHost}user/queryUserDetail`;

  //
  private ARRANGE_LEASE_BY_LEASES = `${environment.apiHost}user/lease/arrange/byLeaseIds`;

  // 检测员工是否认证微信服务号
  private INSPECT_WECHAT_FLAG = `${environment.apiHost}user/wechat/relate/flag`;


  // 员工人脸详情
  private SYSUSER_FACE_DETAIL = `${environment.apiHost}sysuser/face/detail`;

  // 员工人脸列表
  private SYSUSER_FACE_LIST = `${environment.apiHost}sysuser/face/list`;

  // 员工人脸删除
  private SYSUSER_FACE_DELETE = `${environment.apiHost}sysuser/face/delete`;

  // 保洁/维修工单账号获取
  private USER_SYS_WORKER = `${environment.apiHost}user/sysWorker/list`;
  // 维修类型列表获取
  private GET_REPAIR_TYPE = `${environment.apiHost}manage/repair/repairSpeciesTypeList`;
  // 服务工单配置查询
  private SERVICE_CONFIG_INFO = `${environment.apiHost}manage/repair/selectRepariConfigInfo`;
  // 服务工单配置
  private EDIT_SERVICE_CONFIG = `${environment.apiHost}manage/repair/addRepairConfigInfo`;
  // 维修人员工作日查询
  private GET_WORK_DAYLIST = `${environment.apiHost}manage/repair/selectWorkerRestDayList`;
  // 维修人员工作日编辑
  private EDIT_WORK_DAYLIST = `${environment.apiHost}manage/repair/modifyWorkerRestDay`;
  // 维修员列表
  private GET_USER_REPAIR = `${environment.apiHost}user/repair/worker/list`;
  // 手动分配维修员
  private DISTR_USER_REPAIR = `${environment.apiHost}manage/repair/service/distribute`;
  // 查询是否需要短信验证
  private NEED_SMS_CHECK = `${environment.apiHost}user/need/sms/check`;
  // 二次校验短信验证
  private LOGIN_SECOND_CHECK = `${environment.apiHost}user/login/second/check`;

  // 发送登录二次校验验证码
  private SEND_LOGIN_CODE = `${environment.apiHost}sms/login/check/code`;

  // 获取用户管理的房间
  private GET_COMPANY_USER_MANGE_ROOM = `${environment.apiHost}user/manage/project/list`;
  // 获取用户管理的房间
  private POST_COMPANY_USER_MANGE_ROOM_RELATION= `${environment.apiHost}user/manage/room/set`;
  // 乐企达注册用户
  private LEQIDA_USER_REGISTER= `${environment.apiHost}leqida/user/register`;
  // 乐企达发送短信验证码
  private LEQIDA_LOGIN_SMS_CODE= `${environment.apiHost}sms/leqida/login/sms/code`;
  private SMS_QUERY = `${environment.apiHost}sms/query`;

  constructor(private requestService: RequestService) {

  }

  public userCodeShow(): Promise<any> {
    return this.requestService.commonGet(this.USER_CODE_SHOW, null);
  }

  public queryUserInfo(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.USER_INFO, requestData);
  }

  public userPositionManage(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.USER_POSITION, requestData);
  }

  public getCompanyList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.COMPANY_LIST, requestData);
  }

  public queryUserList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.USER_LIST, requestData);
  }

  public queryUserListWithLoading(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.USER_LIST, requestData, true);
  }

  public getDeptUserList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.DEPT_USER_LIST, requestData);
  }

  /**
   * @description:用户登录
   * @Author: mazy
   * @Date: 2018/3/8 10:23
   * @Last Modified by: mazy
   * @Last Modified time: 2018/3/8 10:23
   */
  userLogin(login: Login): Promise<any> {
    // return this.requestService.authPost(environment.apiHost+"/user/login", login, "");
    // return this.requestService.authPost("http://192.168.1.70:8080/api/user/login", login, "");
    // 创建http对象，并设置头部信息
    return this.requestService.commonPost(this.USER_LOGIN, login);
  }

  /**
   * @Description:  用户列表查询
   * @Author: zhoujiahao
   * @Date: 2018-03-23  14:37
   */
  public userListquery(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.USER_LIST, requestData);
  }

  /**
   *   用户增加或者修改
   * @param requestData
   * @returns {Promise<any>}
   */
  public userManage(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.USER_MANAGE, requestData);
  }

  public sendCodeRequest(sendCode: SendCode): Promise<any> {
    return this.requestService.commonPost(this.USER_SEND_CODE, sendCode);
  }

  public resetPasswordRequest(resetPassword: ResetPassword): Promise<any> {
    return this.requestService.commonPost(this.USER_RESET_PASSWORD, resetPassword);
  }

  public logout(): Promise<any> {
    return this.requestService.authPost(this.USER_LOG_OUT, null);
  }

  public userProjectList(requestData?: any): Promise<ProjectListResponse> {
    return this.requestService.authGet(this.USER_PROJECT_LIST, requestData);
  }

  public userProjectChange(projectId): Promise<ProjectChangeResponse> {
    return this.requestService.authPost(this.USER_PROJECT_CHANGE, {project_id: projectId});
  }

  public userProjectInfo(projectId): Promise<ProjectChangeResponse> {
    return this.requestService.authGet(this.GET_USER_PROJECT, {project_id: projectId});
  }

  public userTacticsMessage(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.USER_TACTICS_MESSAGE, requestData);
  }

  public kickToken(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.USER_KICKTOKEN, requestData);
  }

  public queryWorkerList(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.WORKER_LIST, requestData);
  }

  public workerManage(requestData?: any): Promise<any> {
    return this.requestService.authPost(this.WORKER_MANAGE, requestData);
  }

  public isHotWater(requestData?: any): Promise<any> {
    return this.requestService.authGet(this.USER_PROJECT_ISHOTWATER, requestData);
  }

  public arrangeLease(arrangeLeaseRequest?: ArrangeLeaseRequest): Promise<any> {
    return this.requestService.authPost(this.LEAVE_ARRANGE, arrangeLeaseRequest);
  }

  public getUserLoginUnusualRecord(loginUnusualRequest?: LoginUnusualRequest): Promise<LoginUnusualResponse> {
    return this.requestService.authGet(this.LOGIN_UNUSUAL_LIST, loginUnusualRequest);
  }

  public addUserLoginUnusualRecord(loginUnusualInserRequest?: LoginUnusualInserRequest): Promise<CommonResponse> {
    return this.requestService.authPost(this.ADD_LOGIN_UNUSUAL_RECORD, loginUnusualInserRequest);
  }

  public settingOftenCity(userOfterCityInserRequest?: UserOfterCityInserRequest): Promise<CommonResponse> {
    return this.requestService.authPost(this.SETTING_OFTEN_CITY, userOfterCityInserRequest);
  }

  public getOftenCityList(loginUnusualRequest?: LoginUnusualRequest): Promise<UserOfterCityResponse> {
    return this.requestService.authGet(this.OFTEN_CITY_LIST, loginUnusualRequest);
  }

  public getLoginHistoryList(request?: any): Promise<any> {
    return this.requestService.authGet(this.LOGIN_HISTORY_LIST, request);
  }

  public saveRoles(request?: any): Promise<any> {
    return this.requestService.authPost(this.SAVE_ROLES, request);
  }

  public saveCurrentCompany(request?: any): Promise<any> {
    return this.requestService.authPost(this.SAVE_CURRENT_COMPANY, request);
  }

  public userDetail(request?: UserDetailRequest): Promise<UserDetailResponse> {
    return this.requestService.authGet(this.USER_DETAIL, request);
  }

  public arrangeLeaseByLeaseIds(request?: any): Promise<any> {
    return this.requestService.authPost(this.ARRANGE_LEASE_BY_LEASES, request);
  }

  public inspectWechatFlag(request?: UserWechatRelateFlagRequest): Promise<UserWechatRelateFlagResponse> {
    return this.requestService.commonGet(this.INSPECT_WECHAT_FLAG, request);
  }

  public getSysuserFaceList(request?: any): Promise<any> {
    return this.requestService.authGet(this.SYSUSER_FACE_LIST, request);
  }

  public getSysuserFaceDetail(request?: any): Promise<any> {
    return this.requestService.authGet(this.SYSUSER_FACE_DETAIL, request);
  }

  public sysuserFaceDelete(request?: any): Promise<any> {
    return this.requestService.authPost(this.SYSUSER_FACE_DELETE, request);
  }

  public userSysWorker(request?: any): Promise<any> {
    return this.requestService.authGet(this.USER_SYS_WORKER, request);
  }

  public getRepairType(): Promise<any> {
    return this.requestService.authGet(this.GET_REPAIR_TYPE);
  }

  public serviceConfigInfo(request?: any): Promise<any> {
    return this.requestService.authGet(this.SERVICE_CONFIG_INFO, request);
  }

  public editServiceConfig(request?: any): Promise<any> {
    return this.requestService.authPost(this.EDIT_SERVICE_CONFIG, request);
  }

  public getWorkDayList(request?: any): Promise<any> {
    return this.requestService.authGet(this.GET_WORK_DAYLIST, request);
  }

  public editWorkDayList(request?: any): Promise<any> {
    return this.requestService.authPost(this.EDIT_WORK_DAYLIST, request);
  }

  public getUserRepair(): Promise<any> {
    return this.requestService.authGet(this.GET_USER_REPAIR);
  }

  public distrUserRepair(request?: any): Promise<any> {
    return this.requestService.authPost(this.DISTR_USER_REPAIR, request);
  }

  public needSmsCheck(request: SmsCodeCheckRequest): Promise<any> {
    return this.requestService.commonPost(this.NEED_SMS_CHECK, request);
  }

  public loginSecondCheck(request: LoginSecondCheckRequest): Promise<any> {
    return this.requestService.commonPost(this.LOGIN_SECOND_CHECK, request);
  }

  public sendLoginCheckCode(loginCheckCode: LoginCheckCode): Promise<any> {
    return this.requestService.commonPost(this.SEND_LOGIN_CODE, loginCheckCode);
  }


  public getCompanyUserManageRoom(req: UserProjectListRequest): Promise<ManageProjectListResponse> {
    return this.requestService.authGet(this.GET_COMPANY_USER_MANGE_ROOM, req);
  }

  public saveCompanyUserRoomRelation(req: UserProjectRoomRelationRequest): Promise<any> {
    return this.requestService.authPost(this.POST_COMPANY_USER_MANGE_ROOM_RELATION, req);
  }

  public leQiDaUserRegister(req: LeQiDaUserRegisterRequest): Promise<LeQiDaUserRegisterResponse> {
    return this.requestService.commonPost(this.LEQIDA_USER_REGISTER, req);
  }

  public leQiDaLoginSmsCode(req: LeQiDaLoginSmsCodeRequest): Promise<any> {
    return this.requestService.commonPost(this.LEQIDA_LOGIN_SMS_CODE, req);
  }

  public smsQuery(req): Promise<any> {
    return this.requestService.authGet(this.SMS_QUERY, req);
  }
}
